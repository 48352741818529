var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.noData)?_c('empty-list'):_c('div',[(_vm.fields.length === 0 && !_vm.noData)?_c('skeleton',{attrs:{"grid":"1","rows":20,"height":"30px"}}):_vm._e(),_c('div',[_c('table',{staticClass:"d-md-table table b-table table-sm table-responsive w-100"},[_c('thead',{},[_c('tr',{},_vm._l((_vm.fields),function(head,index){return _c('th',{key:index,class:index === 1 ? 'text-left pl-0' : 'text-right px-3'},[_vm._v(" "+_vm._s(head)+" ")])}),0)]),_c('tbody',_vm._l((_vm.accounting_entry.data),function(item,i){return _c('tr',{key:i,class:!item.is_editable && 'disabled'},[_vm._l((item.entries[_vm.period]),function(subItem,idx){return [(idx === 0)?_c('td',{key:idx,staticClass:"align-middle text-right pr-0"},[_c('strong',[_vm._v(_vm._s(item.label)+" - ")])]):_vm._e(),(idx === 0)?_c('td',{key:`T-${idx}`,staticClass:"align-middle text-left"},[_c('strong',[_vm._v(" "+_vm._s(item.name))])]):_vm._e(),_c('td',{key:`item-${idx}`,staticClass:"align-middle text-right"},[_c('b-form-group',{staticClass:"p-0 m-0 table"},[(!item.is_editable)?_c('span',{staticClass:"input-loading table-field w-100 disabled-input d-flex align-items-center justify-content-end",class:{
                      //negative: !item.has_positive_value || subItem.value < 0,                        
                      negative: subItem.value < 0,                        
                      updating:
                        idx === _vm.columnIndex && i === _vm.rowIndex && _vm.loading,
                    },attrs:{"type":"text"}},[_vm._v(" "+_vm._s(_vm.formatLabel(subItem, item))+" "),_c('b-icon',{staticClass:"ml-2 calc",attrs:{"icon":"calculator","id":`calculation-${idx}-${i}`}}),_c('b-popover',{attrs:{"target":`calculation-${idx}-${i}`,"placement":"top","title":"Cáculo da célula","triggers":"hover"}},[_c('strong',[_vm._v(_vm._s(_vm.getCalcCel(item.formula)))])]),(
                        idx === _vm.columnIndex &&
                          _vm.rowUpdating.dependents.includes(item.label) &&
                          _vm.loading
                      )?_c('b-icon',{staticClass:"disc",attrs:{"icon":"disc"}}):_vm._e()],1):_vm._e(),_c('section',{staticClass:"input-loading"},[(subItem.value !== null && item.is_editable)?_c('money',_vm._b({staticClass:"table-field text-right w-100",class:{
                        negative: subItem.value < 0,                          
                        updating:
                          idx === _vm.columnIndex && i === _vm.rowIndex && _vm.loading,
                      },attrs:{"tabindex":idx,"type":"text","disabled":!item.is_editable ||
                          !_vm.user.enterprise ||
                          !_vm.user.enterprise.is_provider,"value":subItem.value},nativeOn:{"blur":function($event){return _vm.handleChangeRow(subItem, $event, idx, i, item)}}},'money',_vm.maskMoney(item, subItem),false)):_vm._e()],1)])],1)]})],2)}),0)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }