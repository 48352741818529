<template>
  <div class="d-flex align-items-start">
    <context-menu ref="context">
      <skeleton height="30px" rows="8" grid="1" gap="8" v-if="isBusy" />
      <div v-else>
        <b-button class="text-left px-3" :variant="period === 'years' ? 'primary' : 'light'" v-b-toggle="'menu_years'">
          <span>
            <b-icon class="mr-1" icon="file-spreadsheet" /> Anos consolidados
          </span>
        </b-button>
        <ul class="list-unstyled">
          <li :class="period === 'years' ? 'active' : ''">
            <b-link @click="handleChangePeriod('years')">
              Preencher Dados
            </b-link>
          </li>
          <li>
            <b-link @click="handleAddFile(0)">
              <div v-if="checkFileType(0)">
                <b-icon icon="patch-check" class="mr-2" />
                <span class="text-nowrap">Balanço Patrimonial Anexado</span>
              </div>
              <div v-else>
                <b-icon icon="paperclip" />
                <span class="text-nowrap">Anexar Balanço Patrimonial</span>
              </div>
            </b-link>
          </li>
          <li>
            <b-link @click="handleAddFile(1)">
              <div v-if="checkFileType(1)">
                <b-icon icon="patch-check" class="mr-2" />
                <span class="text-nowrap">DRE Anexada</span>
              </div>
              <div v-else>
                <b-icon icon="paperclip" />
                <span class="text-nowrap">Anexar DRE</span>
              </div>
            </b-link>
          </li>
        </ul>
        <b-button class="text-left px-3 line-height-1" :variant="period === 'quarters' ? 'primary' : 'light'"
          v-b-toggle="'menu_quarter'">
          <span>
            <b-icon class="mr-1" icon="table" /> Descritivo trimestral</span>
        </b-button>
        <ul class="list-unstyled">
          <li :class="period === 'quarters' ? 'active' : ''">
            <b-link @click="handleChangePeriod('quarters')">
              Preencher Dados
            </b-link>
          </li>
          <li>
            <b-link @click="handleAddFile(2)">
              <div v-if="checkFileType(2)">
                <b-icon icon="patch-check" class="mr-2" />
                <span class="text-nowrap">Balanço Patrimonial Anexado</span>
              </div>
              <div v-else>
                <b-icon icon="paperclip" />
                <span class="text-nowrap">Anexar Balanço Patrimonial</span>
              </div>
            </b-link>
          </li>
          <li>
            <b-link @click="handleAddFile(3)">
              <div v-if="checkFileType(3)">
                <b-icon icon="patch-check" class="mr-2" />
                <span class="text-nowrap">DRE Anexada</span>
              </div>
              <div v-else>
                <b-icon icon="paperclip" />
                <span class="text-nowrap">Anexar DRE</span>
              </div>
            </b-link>
          </li>
        </ul>
      </div>
    </context-menu>
    <b-card class="w-100 min-h-62 mobile-transparent">
      <Table :period="period" ref="table" />
    </b-card>
    <b-modal id="add-financial-file" size="md" hide-footer centered :title="selectedFile.name">
      <b-card no-body class="p-2" v-if="checkDataFile()">
        <span class="d-block mb-2">
          Arquivo anexado em
          {{ selectedFile.file.created_at | dateFull }}
        </span>
        <div class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            mb-1
          ">
          <a class="text-left d-flex align-items-center line-height-1" v-bind:href="selectedFile.file.file"
            target="_blank" download>
            <b-icon icon="paperclip" class="mr-2 m-0" />
            <span> {{ selectedFile.file.name }}</span>
          </a>
          <a @click="handleDeleteFile(selectedFile.file)" size="sm" class="mb-0 icon-action">
            <b-icon icon="trash" class="pointer" />
          </a>
        </div>
      </b-card>

      <file-upload @upload="handleUploadFile" />
    </b-modal>
  </div>
</template>
<style scoped>
.list-unstyled {
  padding-inline-start: 30px;
  list-style: none;
}

ul li {
  margin-top: 20px
}
</style>
<script>
import Table from "./Income.vue";
import FileUpload from "@/components/FileUpload";
import { mapGetters } from "vuex";
import { api } from "@/services/api";

export default {
  components: {
    Table,
    FileUpload
  },
  data() {
    return {
      selectedMenu: {
        url: "",
      },
      period: "years",
      financialFile: [
        { type: 0, name: "Balanço Patrimonial dos anos em análise", file: {} },
        { type: 1, name: "DRE dos anos em análise", file: {} },
        { type: 2, name: "Balanço Patrimonial Trimestrais em análise", file: {} },
        { type: 3, name: "DRE Trimestrais em análise", file: {} },
      ],
      selectedFile: {},
      isBusy: true
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    handleChangePeriod(period) {
      this.period = period;
      this.$refs.context.close();
      this.$refs.table.getTable();
    },
    handleAddFile(type) {
      this.selectedFile = this.financialFile.find(file => file.type === type);
      this.$bvModal.show("add-financial-file");
    },
    handleUploadFile(files) {
      const financialFileItem = this.financialFile.find(file => file.type === this.selectedFile.type);
      const file_uploaded = files[0]
      const data = {
        provider: this.user.enterprise_id,
        type: financialFileItem.type,
        file: file_uploaded.id,
      }
      api.post("/financial-files/", data).then(() => {
        this.$bvModal.hide("add-financial-file");
        this.getFiles()
      });
    },
    handleDeleteFile(file) {
      api.delete(`/upload/${file.id}`).then(() => {
        const financialFileItem = this.financialFile.find(file => file.type === this.selectedFile.type);
        if (financialFileItem) {
          financialFileItem.file = {};
        }
        this.$bvModal.hide("add-financial-file");
      });
    },
    checkDataFile() {
      return (Object.keys(this.selectedFile).length > 0 && Object.keys(this.selectedFile.file).length > 0)
    },
    checkFileType(type) {
      const financialFileItem = this.financialFile.find(file => file.type === type);
      if (Object.keys(financialFileItem.file).length > 0) {
        return true
      } else {
        return false
      }
    },
    getFiles() {
      const params = { provider: this.user.enterprise_id }
      api.get("/financial-files/", { ...params }).then(({ data }) => {
        data.results.forEach(result => {
          const financialFileItem = this.financialFile.find(item => item.type === result.type);
          if (financialFileItem) {
            financialFileItem.file = result.file;
          }
        });
        this.isBusy = false
      });
    }
  },
  created() {
    this.selectedMenu.url = this.$route.fullPath;
    this.getFiles()
  },
};
</script>
