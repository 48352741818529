<template>
  <div>
    <empty-list v-if="noData" />
    <div v-else>
      <skeleton
        grid="1"
        :rows="20"
        v-if="fields.length === 0 && !noData"
        height="30px"
      />
      <div>
        <table class="d-md-table table b-table table-sm table-responsive w-100">
          <thead class="">
            <tr class="">
              <th
                :class="index === 1 ? 'text-left pl-0' : 'text-right px-3'"
                v-for="(head, index) in fields"
                :key="index"
              >
                {{ head }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in accounting_entry.data"
              :key="i"
              :class="!item.is_editable && 'disabled'"
            >
              <template v-for="(subItem, idx) in item.entries[period]">
                <td
                  class="align-middle text-right pr-0"
                  :key="idx"
                  v-if="idx === 0"
                >
                  <strong>{{ item.label }} - </strong>
                </td>
                <td
                  class="align-middle text-left"
                  :key="`T-${idx}`"
                  v-if="idx === 0"
                >
                  <strong> {{ item.name }}</strong>
                </td>

                <td class="align-middle text-right" :key="`item-${idx}`">
                  <b-form-group class="p-0 m-0 table">
                    <span
                      v-if="!item.is_editable"
                      type="text"
                      class="
                        input-loading
                        table-field
                        w-100
                        disabled-input
                        d-flex
                        align-items-center
                        justify-content-end
                      "
                      :class="{
                        //negative: !item.has_positive_value || subItem.value < 0,                        
                        negative: subItem.value < 0,                        
                        updating:
                          idx === columnIndex && i === rowIndex && loading,
                      }"
                    >
                      {{ formatLabel(subItem, item) }}
                      <b-icon
                        icon="calculator"
                        class="ml-2 calc"
                        :id="`calculation-${idx}-${i}`"
                      />
                      <b-popover
                        :target="`calculation-${idx}-${i}`"
                        placement="top"
                        title="Cáculo da célula"
                        triggers="hover"
                      >
                        <strong>{{ getCalcCel(item.formula) }}</strong>
                      </b-popover>
                      <b-icon
                        icon="disc"
                        class="disc"
                        v-if="
                          idx === columnIndex &&
                            rowUpdating.dependents.includes(item.label) &&
                            loading
                        "
                      />
                    </span>
                    <section class="input-loading">
                      <money
                        :tabindex="idx"
                        v-if="subItem.value !== null && item.is_editable"
                        type="text"
                        :disabled="
                          !item.is_editable ||
                            !user.enterprise ||
                            !user.enterprise.is_provider
                        "
                        v-bind="maskMoney(item, subItem)"
                        @blur.native="
                          handleChangeRow(subItem, $event, idx, i, item)
                        "
                        :value="subItem.value"
                        class="table-field text-right w-100"
                        :class="{
                          negative: subItem.value < 0,                          
                          updating:
                            idx === columnIndex && i === rowIndex && loading,
                        }"
                      />
                    </section>
                  </b-form-group>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { Money } from "v-money";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: {
    Money,
  },
  props: {
    period: {
      type: String,
    },
  },
  data() {
    return {
      rowUpdating: null,
      loading: false,
      id: "",
      columnIndex: null,
      rowIndex: null,
      accounting_entry: {
        years: [],
      },
      periods: [],
      homologation: "",
      fields: [],
      noData: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions([
      "get_all_balance_sheet",
      "edit_balance_sheet",
      "edit_accounting_entry",
    ]),
    formatLabel({ value }, { type_view }) {
      if (typeof value !== "number") {
        parseInt(value);
      }
      if (type_view === "money") {
        const formatter = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BLR",
        });
        return "R$ " + formatter.format(value).replace("BLR", "");
      }
      return `${new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value * 100)}%`;
    },
    handleChangeRow: _.debounce(function(
      cell,
      event,
      columnIndex,
      rowIndex,
      row
    ) {
      event.stopPropagation();
      if (event.target?.value) {
        this.updateTable(cell, event.target?.value, columnIndex, rowIndex, row);
      }
    },
    50),

    updateTable(cell, event, columnIndex, rowIndex, row) {
      this.loading = true;
      this.rowUpdating = row;
      this.columnIndex = columnIndex;
      this.rowIndex = rowIndex;
      const value = parseFloat(
        event
          .replaceAll(".", "")
          .replace(",", ".")
          .replace("R$ ", "")
          .replace("--", "-")
      );

      this.edit_accounting_entry({
        ...cell,
        value,
      }).then(() => this.updateDependents(row));

      this.$forceUpdate();
    },
    handleChangePeriod(period) {
      this.period = period;
      if (period === "years") {
        this.fields = ["Código", "Descrição", ...this.accounting_entry.years];
      } else {
        const quartes = this.accounting_entry.quarters;

        if (!quartes) {
          return;
        }

        this.fields = [
          "Código",
          "Descrição",
          ...quartes.map((q) => `${q[0]}T ${q[1]}`),
        ];
      }
    },
    updateDependents(row) {
      this.get_all_balance_sheet({
        codes: row.dependents.join(","),
      })
        .then(({ data, status }) => {
          if (![200, 201].includes(status)) {
            this.noData = true;
            return;
          }
          row.dependents.map((dependent) => {
            this.accounting_entry.data.map(({ code, entries }) => {
              if (dependent === code) {
                const ent = data.data.find((d) => code === d.code);
                entries[this.period] = ent.entries[this.period];
              }
            });
          });
          this.loading = false;
        })
        .catch(() => {
          this.noData = true;
        });
    },
    getTable() {
      this.get_all_balance_sheet().then(({ data, status }) => {
        if (![200, 201].includes(status)) {
          this.noData = true;
          return;
        }
        this.accounting_entry = data;
        this.homologation = data.homologation;
        this.loading = false;

        this.handleChangePeriod(this.period);
      });
    },

    maskMoney(item, subItem) {      
      if (subItem.value >= 0) {
        return {
          decimal: ",",
          thousands: ".",
          prefix: "R$ ",
          suffix: "",
          precision: 2,
          masked: true,
        };
      }
      return {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: true,
      };
    },

    getCalcCel(form) {
      const removedParentheses = form.replace(/[\\[\]()]/g, "").split(" ");

      const dataAccountingEntry = this.accounting_entry.data;

      const flatList = dataAccountingEntry.map(({ name, code, formula }) => ({
        name,
        code,
        formula,
      }));

      const arrFormulas = [];

      removedParentheses.forEach((element) => {
        if (["/", "*", "+", "-", "."].includes(element)) {
          arrFormulas.push(element);
        }
        flatList.forEach((data) => {
          if (element == data.code) {
            arrFormulas.push(data.name);
          }
        });
      });

      const stringformula = arrFormulas.join(" ");
      return stringformula;
    },
  },
  mounted() {
    this.getTable();
  },
  watch: {
    period(period) {
      this.handleChangePeriod(period);
    },
  },
};
</script>
<style lang="scss" scoped>
.input-loading {
  &.table {
    svg {
      right: auto;
      left: 5px;
      top: 4px;
      display: block !important;
    }
  }
}
tr {
  font-size: 13px;
  &.disabled {
    background: #dae4f5;
    td {
      color: #212529;
    }
  }
}
.table-field {
  border-color: transparent;
  height: 30px !important;
  &.disabled-input {
    margin-right: 13px;
    border-color: transparent !important;
    .calc {
      position: relative;
      top: auto;
      right: auto;
      opacity: 0.7;
      display: flex !important;
      animation: none !important;
    }
    .disc {
      display: flex !important;
      right: auto;
      left: 10px;
      top: 10px;
    }
  }
  &.negative {
    color: $danger !important;
  }
  &.updating {
    text-shadow: 0 0 5px rgba(223, 223, 223, 0.5);
  }
}
</style>
